import React from 'react'

import { i18n } from '@lingui/core'
import { Trans, defineMessage, t } from '@lingui/macro'
import { parseISO } from 'date-fns'

import { ErpOrderCancellation } from 'src/api/erp-order-cancellation/types'
import { ErpOrder } from 'src/api/erp-order-page-search/types'

import { ERP_ORDER_CANCELLATION_STATES } from 'src/hooks/data/useErpOrderCancellations'

import ApprovalCancellationRequestButton from 'src/components/ApprovalCancellationRequestButton'
import Button from 'src/components/Button'
import Currency from 'src/components/Currency'
import RejectCancellationRequestButton from 'src/components/RejectCancellationRequestButton'
import RejectCancellationRequestDialogContainer from 'src/components/RejectCancellationRequestDialogContainer'
import State from 'src/components/State'

defineMessage({ id: 'Edit', message: 'Edit' })
defineMessage({ id: 'Approve', message: 'Approve' })

type DetailProps = {
  cancellationRequest: ErpOrderCancellation
  cancelationFormIsVisible: boolean
  hideCancelationForm: boolean
  order: ErpOrder
  showCancelationForm: boolean
}

const Detail: React.FC<DetailProps> = ({
  cancellationRequest,
  cancelationFormIsVisible,
  hideCancelationForm,
  order,
  showCancelationForm,
}) => {
  const [
    showRejectCancellationRequestDialog,
    setShowRejectCancellationRequestDialog,
  ] = React.useState(false)

  const closeRejectCancellationRequestDialog = () => {
    setShowRejectCancellationRequestDialog(false)
  }

  const cancellationTotal = cancellationRequest?.cancellationItems?.reduce(
    (accum: number, item: any) => accum + item?.cancellationValue || 0,
    0
  )

  const cancellationReasons = {
    '007': t`Order change`,
    '013': t`Business closure`,
    '029': t`Solvency problems`,
    '031': t`Full stock / Overstock`,
    '032': t`Racer vs. bum swap`,
  }

  return (
    <div className="space-y-4 bg-white p-6">
      <div className="flex w-full justify-between">
        <div className="flex flex-1 flex-col">
          <div className="text-xs text-gray-500">
            <Trans>Shipping address</Trans>
          </div>
          <div>
            <ul>
              <li>{order.shippingAddress.name1}</li>
              <li>{order.shippingAddress.address1}</li>
              <li>
                {order.shippingAddress.zipCode} {order.shippingAddress.city}
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-1 flex-col space-y-2 lg:flex-3 lg:flex-row lg:justify-between lg:space-y-0">
          <div>
            <div className="mb-5">
              <div className="text-xs text-gray-500">
                <Trans>Order date</Trans>
              </div>
              <div>
                {!!order.createdAt &&
                  i18n.date(new Date(order.createdAt * 1000).toISOString(), {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">
                <Trans>Cancellation Date</Trans>
              </div>
              <div>
                {!!cancellationRequest?.createdAt &&
                  i18n.date(parseISO(cancellationRequest?.createdAt), {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
              </div>
            </div>
          </div>
          <div>
            <div className="mb-5">
              <div className="text-xs text-gray-500">
                <Trans>Cancelled from</Trans>
              </div>
              <div>
                {cancellationRequest?.customer?.firstName}{' '}
                {cancellationRequest?.customer?.lastName}
              </div>
              <div>{cancellationRequest?.customer?.email}</div>
            </div>
            <div>
              <div className="text-xs text-gray-500">
                <Trans>Cancellation reason</Trans>
              </div>
              <div>
                {
                  cancellationReasons[
                    cancellationRequest?.reasonForCancellation
                  ]
                }
              </div>
            </div>
          </div>
          <div>
            <div className="text-xs text-gray-500">
              <Trans>Order value</Trans>
            </div>
            <div>
              <Currency
                cents={order.totals.grandTotal}
                currencyIsoCode={order.currencyIsoCode}
              />
            </div>
          </div>
          <div>
            <div className="text-xs text-gray-500">
              <Trans>Amount canceled</Trans>
            </div>
            <div>
              <Currency
                cents={cancellationTotal}
                currencyIsoCode={order.currencyIsoCode}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="float-right">
        {cancellationRequest?.state !== ERP_ORDER_CANCELLATION_STATES.ready ? (
          <State
            variant={cancellationRequest.state}
            state={t({ id: `state_${cancellationRequest?.state}` })}
          ></State>
        ) : (
          <div>
            {cancelationFormIsVisible ? (
              <Button
                type="reset"
                variant="gray"
                size="xl"
                onClick={hideCancelationForm}
              >
                <Trans>Cancel</Trans>
              </Button>
            ) : (
              <div className="flex space-x-4">
                <Button
                  variant={cancelationFormIsVisible ? 'grey' : 'purple'}
                  size="xl"
                  onClick={
                    cancelationFormIsVisible
                      ? hideCancelationForm
                      : showCancelationForm
                  }
                >
                  {cancelationFormIsVisible ? (
                    <Trans>Approve</Trans>
                  ) : (
                    <Trans>Edit</Trans>
                  )}
                </Button>
                <div>
                  <ApprovalCancellationRequestButton
                    cancellationRequest={cancellationRequest}
                    size="xl"
                  />
                </div>
                <div>
                  <RejectCancellationRequestButton
                    setShowRejectCancellationRequestDialog={
                      setShowRejectCancellationRequestDialog
                    }
                    size="xl"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {showRejectCancellationRequestDialog && (
        <RejectCancellationRequestDialogContainer
          cancellationRequest={cancellationRequest}
          onClose={closeRejectCancellationRequestDialog}
        />
      )}
    </div>
  )
}

export default Detail
