import { Fragment } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { ArrowLeftIcon } from '@heroicons/react/24/outline'

import useErpOrderCancellations from 'src/hooks/data/useErpOrderCancellations'

import useBoolean from 'src/hooks/utils/useBoolean'

import { H1 } from 'src/components/Header'
import ListItemSkeleton from 'src/components/ListItemSkeleton'

import CancelationRequestForm from 'src/pages/Tools/CancellationRequest/components/CancellationRequestForm'
import CancellationRequestDetail from 'src/pages/Tools/CancellationRequest/components/Detail'
import CancellationRequesItemsTable from 'src/pages/Tools/CancellationRequest/components/ItemsTable'

const CancellationRequest = () => {
  const { cancellationRequestId } = useParams()

  const [cancelationFormIsVisible, showCancelationForm, hideCancelationForm] =
    useBoolean(false)

  const useErpOrderCancellationsQuery = useErpOrderCancellations({
    uuid: cancellationRequestId,
  })

  const cancellationRequest = useErpOrderCancellationsQuery.data?.[0]

  if (useErpOrderCancellationsQuery.isLoading) {
    return (
      <div className="divide-y divide-gray-200 bg-white">
        {Array.from({ length: 3 }, (_, index) => (
          <ListItemSkeleton key={index} />
        ))}
      </div>
    )
  }

  const sortOption = { key: 'line_id' }
  return (
    <Fragment>
      <Link
        to=".."
        className="absolute -mt-3 flex -translate-y-full transform items-center space-x-1 text-sm font-light text-gray-600 hover:underline"
      >
        <ArrowLeftIcon className="w-5" />
        <span>
          <Trans>Cancellation requests</Trans>
        </span>
      </Link>

      <div className="flex grow flex-col space-y-5">
        <div className="flex items-end justify-between">
          <H1>
            <Trans>Cancellation request</Trans>{' '}
            {cancellationRequest?.erpOrderExternalReference}
          </H1>
        </div>
        <CancellationRequestDetail
          cancellationRequest={cancellationRequest}
          cancelationFormIsVisible={cancelationFormIsVisible}
          hideCancelationForm={hideCancelationForm}
          order={cancellationRequest.erpOrder}
          showCancelationForm={showCancelationForm}
        />
        {!!cancellationRequest &&
          (cancelationFormIsVisible ? (
            <CancelationRequestForm
              cancellationRequest={cancellationRequest}
              discardCancelation={hideCancelationForm}
              sortOption={sortOption}
            />
          ) : (
            <CancellationRequesItemsTable
              cancellationRequest={cancellationRequest}
            />
          ))}
      </div>
    </Fragment>
  )
}

export default CancellationRequest
