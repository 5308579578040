import React, { ReactElement } from 'react'
import { Link, RouteObject, useLoaderData } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { ArrowLeftIcon } from '@heroicons/react/24/outline'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import lazyRetry from 'src/lib/lazyRetry'
import { getIsLoggedIn } from 'src/routes/utils'

const Imprint = React.lazy(() => lazyRetry(() => import('src/pages/Imprint')))

const TermsOfService = React.lazy(() =>
  lazyRetry(() => import('src/pages/TermsOfService'))
)

const loader = async () => {
  const isLoggedIn = await getIsLoggedIn()
  return isLoggedIn
}

const PageWrapper = ({ children }: { children: ReactElement }) => {
  const isLoggedIn = useLoaderData() as boolean

  return (
    <div className="container mx-auto space-y-8 px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
      {!isLoggedIn && (
        <Link
          to=".."
          className="absolute -mt-3 flex -translate-y-full transform items-center space-x-1 text-sm font-light text-gray-600 hover:underline"
        >
          <ArrowLeftIcon className="w-5" />
          <span>
            <Trans>Back</Trans>
          </span>
        </Link>
      )}
      {children}
    </div>
  )
}

const staticRoutes: RouteObject[] = [
  {
    path: 'imprint',
    loader,
    element: (
      <PageWrapper>
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'imprint',
          }}
        >
          <Imprint />
        </DataLayerPushWrapper>
      </PageWrapper>
    ),
  },
  {
    path: 'terms-of-service',
    loader,
    element: (
      <PageWrapper>
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'terms-of-service',
          }}
        >
          <TermsOfService />
        </DataLayerPushWrapper>
      </PageWrapper>
    ),
  },
]

export default staticRoutes
