import React, { ForwardedRef, ReactNode } from 'react'

import useDialogContext from 'src/components/Dialog/useDialogContext'

type DialogContentProps = {
  children: ReactNode
  asChild?: boolean
  className?: string
}

export const DialogContent: React.ForwardRefExoticComponent<DialogContentProps> =
  React.forwardRef(
    (
      { children, asChild = false, ...props }: DialogContentProps,
      ref: ForwardedRef<HTMLDivElement>
    ) => {
      const context = useDialogContext()

      if (typeof children === 'function') {
        return (children as (context: any) => ReactNode)(context) as JSX.Element
      }

      if (asChild && React.isValidElement(children)) {
        return React.cloneElement(children, {
          ...props,
          ...(children?.props ? children.props : {}),
        })
      }

      return (
        <div {...props} ref={ref}>
          {children}
        </div>
      )
    }
  )
