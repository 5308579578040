import React, { Fragment } from 'react'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'

import { ArrowRightIcon } from '@heroicons/react/24/outline'

import useObserver from 'src/hooks/utils/useObserver'

import Button from 'src/components/Button'
import Currency from 'src/components/Currency'

import Availability from 'src/pages/Carts/CartGrid/components/Item/Availability'
import AvailabilityLabel from 'src/pages/Carts/CartGrid/components/Item/AvailabilityLabel'
import { QuantityInput } from 'src/pages/Carts/CartGrid/components/Item/Quantity'
import Total from 'src/pages/Carts/CartGrid/components/Item/Total'
import Images from 'src/pages/Carts/CartGrid/components/Item/images'

type ItemProps = {
  product: any
  currency: string
  pricesObserver: any
  updateCartItems: any
  availabilityObserver: any
  cartObserver: any
  openProductDetailsDialog: (product: any) => void
}

const Item: React.FC<ItemProps> = ({
  product,
  currency,
  pricesObserver,
  updateCartItems,
  availabilityObserver,
  cartObserver,
  openProductDetailsDialog,
}) => {
  const id = `${product.id}.earliest-date`
  const { quantity, messages } = useObserver(
    cartObserver,
    (data: any) => ({
      quantity: data.items[id]?.quantity || 0,
      companyUserReference: data.companyUserReference,
      messages: data.items[id]?.messages,
    }),
    [id]
  )

  let cellState
  if (quantity > 0) {
    cellState =
      messages === null
        ? 'pending'
        : messages && messages.length === 0
        ? 'valid'
        : messages && messages.length > 0 && 'invalid'
  }

  const isSaleable =
    product?.stockStatus &&
    (product.stockStatus !== 0 ||
      product.stockStatus !== undefined ||
      product.stockStatus !== null)
      ? true
      : false

  const resolveCurrencyIsoCode = (currency: any, product: any) => {
    if (currency !== undefined) {
      return currency
    }

    return product.prices?.[0]?.currency?.code
  }

  const currencyIsoCode = resolveCurrencyIsoCode(currency, product)

  const price = useObserver(
    pricesObserver,
    (data: any) => {
      const price = data?.[product.id]
      if (!price) {
        return false
      }
      return price
    },
    [product.id]
  )

  const onChange = ({ id, value }: { id: string; value: number }) => {
    updateCartItems({
      [id]: value,
    })
  }

  return (
    <Fragment>
      <div className="flex flex-col rounded-md border bg-white">
        <div
          className="flex w-full cursor-pointer md:h-80"
          onClick={openProductDetailsDialog}
        >
          <div className="relative h-full w-full bg-gray-100 ">
            <div className="flex h-full items-center justify-center border-b">
              <Images
                images={product.images}
                className="h-auto max-h-full w-full"
              />
            </div>
            <div className="absolute bottom-0 w-full">
              <AvailabilityLabel product={product} />
            </div>
          </div>
          <div
            className={cn(
              'relative mt-2.5 w-full border-b-0 px-3.5 md:border-b',
              {
                'border-b': isSaleable,
              }
            )}
          >
            <button
              type="button"
              className="float-right mb-4 flex items-center"
            >
              <span className="mr-1">
                <Trans>Details</Trans>
              </span>
              <span>
                <ArrowRightIcon className="h-4 w-4" />
              </span>
            </button>
            <div className="flex w-full text-xs">
              <span>{product.brand}</span>
            </div>
            <div className="max-h-48 w-full py-1 text-lg font-bold">
              <span>
                {product.model} {` • `} {product.style}{' '}
                {product.size &&
                  product.groupSize === 1 &&
                  ` • ` + product.size}
              </span>
            </div>
            <div className="flex w-full flex-wrap pb-2 text-base">
              {product.groupCount > 1 ? (
                <span className="w-full flex-auto"></span>
              ) : (
                <span className="w-full flex-auto">{product.id}</span>
              )}
            </div>
            {((!!product.allowedQuantity.quantityInterval &&
              product.allowedQuantity.quantityInterval > 1) ||
              (!!product.allowedQuantity.quantityMax &&
                product.allowedQuantity.quantityMax !== Infinity)) && (
              <div className="absolute bottom-6 left-3.5">
                <div className="flex w-full flex-row justify-around space-x-3">
                  <div className="space-x-2">
                    <span>
                      <Trans>Interval</Trans>:
                    </span>
                    <span>{product.allowedQuantity.quantityInterval}</span>
                  </div>
                  <div className="space-x-2">
                    <span>
                      <Trans>Max</Trans>:
                    </span>
                    <span>{product.allowedQuantity.quantityMax}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="absolute bottom-1 right-3.5">
              <div className="flex w-full justify-end text-base font-semibold">
                <div className="mr-1">
                  <Trans>Price</Trans>:
                </div>
                <div>
                  <Currency
                    cents={!!price ? price : 0}
                    currencyIsoCode={
                      !!currencyIsoCode ? currencyIsoCode : 'EUR'
                    }
                  />{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        {product.groupCount > 1 ? (
          <div className="flex max-h-28 w-full grow flex-col items-center justify-center px-3.5 py-5 md:min-h-[8rem]">
            <Button
              className="w-full"
              variant="gray"
              size="xl"
              onClick={openProductDetailsDialog}
            >
              <Trans>Select size</Trans>
            </Button>
          </div>
        ) : (
          isSaleable && (
            <div
              className={cn(
                'float-left max-h-28 w-full px-3.5 py-5 md:block md:min-h-[8rem]'
              )}
            >
              <div>
                <div className="flex w-full items-center">
                  <Availability
                    product={product}
                    availabilityObserver={availabilityObserver}
                  />
                  <div className="ml-auto flex">
                    <QuantityInput
                      id={`${product.id}.earliest-date`}
                      initialQuantity={quantity}
                      onChange={onChange}
                      inputState={cellState}
                    />
                  </div>
                </div>
                <div className="h-5">
                  {!!messages && (
                    <div className="w-full text-yellow-600">
                      <span>{messages[0]}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="float-right">
                <Total
                  price={price}
                  currencyIsoCode={currencyIsoCode}
                  quantity={quantity}
                />
              </div>
            </div>
          )
        )}
      </div>
    </Fragment>
  )
}
export default Item
