import React, { Fragment } from 'react'

import useIntersectionObserver from 'src/hooks/data/useIntersectionObserver'

import useHeight from 'src/hooks/utils/useHeight'

import NoSearchResult, {
  LoadingPlaceholder,
} from 'src/components/NoDataPlaceholder'

import NoResultWithActiveSearch from 'src/pages/Carts/Cart/NoResultWithActiveSearch'
import DetailsDialog from 'src/pages/Carts/CartGrid/components/DetailsDialog'
import Item from 'src/pages/Carts/CartGrid/components/Item'
import { Product } from 'src/pages/Carts/CartGrid/components/Item/types'

type GridProps = {
  items: any
  productsQuery: any
  pricesObserver: any
  updateCartItems: any
  cartObserver: any
  hasActiveSearch: boolean
  hasNextPage: boolean
  fetchNextPage: any
  availabilityObserver: any
  fetchNextPricesPage: any
  fetchNextAvailabilitiesPage: any
}

const Grid: React.FC<GridProps> = ({
  items,
  productsQuery,
  pricesObserver,
  updateCartItems,
  cartObserver,
  hasActiveSearch,
  hasNextPage,
  fetchNextPage,
  availabilityObserver,
  fetchNextPricesPage,
  fetchNextAvailabilitiesPage,
}) => {
  const parentRef = React.useRef<HTMLDivElement>(null)
  const loadMoreRef = React.useRef<HTMLLIElement>()

  const [productToShowDetails, setProductToShowDetails] =
    React.useState<Product | null>(null)
  const openProductDetailsDialog = (product: Product) =>
    setProductToShowDetails(product)
  const closeProductDetailsDialog = () => {
    setProductToShowDetails(null)
  }

  const { height, ref: gridHeightRef } = useHeight()

  useIntersectionObserver({
    root: parentRef,
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: !!hasNextPage,
    threshold: 0.5,
  })

  return (
    <Fragment>
      {productToShowDetails && (
        <DetailsDialog
          availabilityObserver={availabilityObserver}
          cartObserver={cartObserver}
          changeDialogProduct={openProductDetailsDialog}
          closeProductGridViewDetailsDialog={closeProductDetailsDialog}
          currency={productsQuery.data.currency}
          product={productToShowDetails}
          pricesObserver={pricesObserver}
          updateCartItems={updateCartItems}
          fetchNextPricesPage={fetchNextPricesPage}
          fetchNextAvailabilitiesPage={fetchNextAvailabilitiesPage}
        />
      )}

      {productsQuery.isSuccess &&
      items.length &&
      !productsQuery.isPreviousData ? (
        <Fragment>
          <div className="relative shrink-0 grow" ref={gridHeightRef}>
            <div
              className="grid grid-cols-1 gap-4 overflow-y-auto p-4 md:grid-cols-2 xl:grid-cols-3"
              style={{ height }}
              ref={parentRef}
            >
              {items.map((item, index) => (
                <Item
                  availabilityObserver={availabilityObserver}
                  cartObserver={cartObserver}
                  currency={productsQuery.data.currency}
                  key={index}
                  openProductDetailsDialog={() => {
                    openProductDetailsDialog(item)
                  }}
                  product={item}
                  pricesObserver={pricesObserver}
                  updateCartItems={updateCartItems}
                />
              ))}
              {hasNextPage && (
                <div ref={loadMoreRef}>
                  <LoadingPlaceholder />
                </div>
              )}
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          {productsQuery.isPreviousData ? (
            <LoadingPlaceholder />
          ) : hasActiveSearch ? (
            <NoResultWithActiveSearch />
          ) : (
            <NoSearchResult />
          )}
        </div>
      )}
    </Fragment>
  )
}

export default Grid
