import { Fragment } from 'react'

import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

import Button from 'src/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContainer,
  DialogContent,
  DialogHeading,
  DialogXButton,
} from 'src/components/Dialog'

type InvalidLinesDialogProps = {
  invalidLines: Record<number, any>
  onClose: () => void
}

const InvalidLinesDialog: React.FC<InvalidLinesDialogProps> = ({
  invalidLines,
  onClose,
}) => (
  <Dialog initialOpen onClose={onClose}>
    <DialogContainer className="space-y-8">
      <DialogXButton />
      <DialogHeading>
        <Trans>Faulty budgets</Trans>
      </DialogHeading>
      <DialogContent>
        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <ExclamationCircleIcon className="h-4 w-4" />
            <span>
              <Trans>The following lines could not be processed:</Trans>
            </span>
          </div>
          <div
            className="grid max-h-80 gap-4 overflow-y-auto"
            style={{ gridTemplateColumns: 'max-content auto' }}
          >
            {Object.entries(invalidLines).map(([key, invalidLine]) => (
              <Fragment key={key}>
                <div>
                  <Trans>Line {parseInt(key, 10)}:</Trans>
                </div>
                <div>
                  {`${invalidLine.company.debtorNumber} - 
                      ${i18n.number(invalidLine.nextInitialBudget / 100, {
                        style: 'currency',
                        currency: 'EUR',
                      })}`}
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogClose asChild>
        <Button variant="purple" size="xl">
          <Trans>OK</Trans>
        </Button>
      </DialogClose>
    </DialogContainer>
  </Dialog>
)
export default InvalidLinesDialog
