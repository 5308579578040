import React, { Fragment, useEffect, useState } from 'react'
import {
  Outlet,
  RouteObject,
  useLoaderData,
  useLocation,
  useRouteError,
} from 'react-router-dom'

import { isAfter, isBefore } from 'date-fns'
import { ToastContainer } from 'react-toastify'

import useCustomer from 'src/hooks/data/useCustomer'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import isIE from 'src/lib/isIE'
import lazyRetry from 'src/lib/lazyRetry'
import { MaintenanceError, UnsupportedBrowserError } from 'src/routes/errors'
import privateRoutes from 'src/routes/private'
import publicRoutes from 'src/routes/public'
import rootIndexRoute from 'src/routes/rootIndex'
import staticRoutes from 'src/routes/static'
import { getIsInitialLogin, getIsLoggedIn } from 'src/routes/utils'

const images = import.meta.glob(
  '/src/assets/images/backgrounds/login/*.{png,jpg}'
)

const MaintenanceHint = React.lazy(() =>
  lazyRetry(() => import('src/components/MaintenanceHint'))
)

const StepByStepGuidesHint = React.lazy(() =>
  lazyRetry(() => import('src/components/StepByStepGuidesHint'))
)

const NavigationBar = React.lazy(() =>
  lazyRetry(() => import('src/layout/NavigationBar'))
)

const Footer = React.lazy(() =>
  lazyRetry(() => import('src/components/Footer'))
)

const PageBlocker = React.lazy(() =>
  lazyRetry(() => import('src/components/PageBlocker'))
)

const UnsupportedBrowserMessage = React.lazy(() =>
  lazyRetry(() => import('src/pages/UnsupportedBrowserMesssage'))
)

const Maintenance = React.lazy(() =>
  lazyRetry(() => import('src/pages/Maintenance'))
)

const mobileBreakpointForBackgroundImages = 768

const PageWrapper = () => {
  const location = useLocation()
  const [randomImage, setRandomImage] = useState('')
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= mobileBreakpointForBackgroundImages
  )
  const showBackgroundImage =
    location.pathname === '/login' ||
    location.pathname === '/forgotten-password'

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileBreakpointForBackgroundImages)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (showBackgroundImage) {
      const imagePaths = Object.keys(images).filter((path) =>
        isMobile ? path.includes('-sm') : path.includes('-xl')
      )
      const randomImagePath =
        imagePaths[Math.floor(Math.random() * imagePaths.length)]
      images[randomImagePath]().then((module: any) => {
        setRandomImage(module.default)
      })
    }
  }, [showBackgroundImage, isMobile])

  const canShowNavigation = useLoaderData() as boolean
  const { data: customer } = useCustomer()
  const loginCount = (customer as any)?.customerStatistic?.loginCount
  const { VITE_MAINTENANCE_HINT_START, VITE_MAINTENANCE_HINT_END } = import.meta
    .env
  const hasMaintenanceHint =
    isAfter(new Date(), new Date(VITE_MAINTENANCE_HINT_START)) &&
    isBefore(new Date(), new Date(VITE_MAINTENANCE_HINT_END))

  return (
    <div
      className="flex h-full w-full flex-col overflow-scroll"
      style={
        showBackgroundImage
          ? {
              backgroundImage: `url(${randomImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
          : {}
      }
    >
      {canShowNavigation && <NavigationBar />}
      <Outlet />
      {!canShowNavigation && <Footer />}
      {canShowNavigation && (
        <Fragment>
          {loginCount < 4 && <StepByStepGuidesHint />}
          {hasMaintenanceHint && <MaintenanceHint />}
        </Fragment>
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  )
}

const ErrorBoundary = () => {
  const error = useRouteError()

  if (error instanceof UnsupportedBrowserError) {
    return (
      <DataLayerPushWrapper
        layer={{
          event: 'vpv_general',
          page_type: 'unsupported_browser',
        }}
      >
        <div className="flex h-full w-full flex-col">
          <PageBlocker>
            <UnsupportedBrowserMessage />
          </PageBlocker>
        </div>
      </DataLayerPushWrapper>
    )
  }

  if (error instanceof MaintenanceError) {
    return (
      <DataLayerPushWrapper
        layer={{
          event: 'vpv_general',
          page_type: 'maintenance',
        }}
      >
        <div className="flex h-full w-full flex-col">
          <PageBlocker>
            <Maintenance />
          </PageBlocker>
        </div>
      </DataLayerPushWrapper>
    )
  }

  return null
}

const loader = async () => {
  if (isIE()) {
    throw new UnsupportedBrowserError()
  }

  const { VITE_MAINTENANCE_START, VITE_MAINTENANCE_END, MODE } = import.meta.env

  const isInMaintenance =
    isAfter(new Date(), new Date(VITE_MAINTENANCE_START)) &&
    isBefore(new Date(), new Date(VITE_MAINTENANCE_END)) &&
    MODE === 'production'

  if (isInMaintenance) {
    throw new MaintenanceError()
  }

  return (await getIsLoggedIn()) && !(await getIsInitialLogin())
}

const routes: RouteObject[] = [
  {
    path: '/',
    loader,
    element: <PageWrapper />,
    errorElement: <ErrorBoundary />,
    children: [
      rootIndexRoute,
      ...privateRoutes,
      ...publicRoutes,
      ...staticRoutes,
    ],
  },
]

export const getRoutes = () => {
  return routes
}
