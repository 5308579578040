import React, { FC, useCallback, useState } from 'react'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import { useDropzone } from 'react-dropzone'

import {
  ArrowUpTrayIcon,
  CheckIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'

import Button from 'src/components/Button'

import { ParsingError } from 'src/forms/components/DropZone/error'
import parseUploadedFile from 'src/forms/components/DropZone/utils'

type DropZoneParsingErrorsProps = {
  parsingErrors: ParsingError[]
}

const DropZoneParsingErrors: FC<DropZoneParsingErrorsProps> = ({
  parsingErrors,
}) => {
  return (
    <ul
      className={cn(
        'h-36 w-full overflow-y-auto rounded border-2 border-red-500 bg-red-200'
      )}
    >
      {parsingErrors.map((parsingError, index) => {
        return <DropZoneParsingError key={index} parsingError={parsingError} />
      })}
    </ul>
  )
}

type DropZoneParsingErrorProps = {
  parsingError: ParsingError
}

const DropZoneParsingError: FC<DropZoneParsingErrorProps> = ({
  parsingError,
}) => {
  return (
    <li className={cn('mx-2 my-1 text-red-500')}>
      <Trans>Line {parsingError.line}:</Trans> {parsingError.message}
    </li>
  )
}

type DropZoneProps<T> = {
  resetDropzone?: boolean
  rowMapper: (row: any[]) => T
  onDropSuccess: (parsedRows: T[], file: File) => void
  onDropError: (errors: ParsingError[]) => void
  onDeleteSuccess: () => void
  error?: string
}

const DropZone = <T,>({
  resetDropzone, //Workaround to reset the uncontrolled dropzone
  rowMapper,
  onDropSuccess,
  onDropError,
  onDeleteSuccess,
  error,
}: DropZoneProps<T>) => {
  const [errors, setErrors] = useState<ParsingError[]>([])
  const [file, setFile] = useState<File>()

  const parsedRowMapper = useCallback(
    (row: any[]) => {
      const trimmedRow = row.map((cell) =>
        typeof cell === 'string' ? cell.trim() : cell
      )

      return rowMapper(trimmedRow)
    },
    [rowMapper]
  )

  const onDrop = useCallback(
    async ([file]: File[]) => {
      setFile(file)

      const parsingResult = await parseUploadedFile<T>(file, parsedRowMapper)

      setErrors(parsingResult.errors)

      if (parsingResult.errors.length > 0) {
        onDropError(parsingResult.errors)
        return
      }

      onDropSuccess(parsingResult.rows, file)
    },
    [parsedRowMapper, onDropSuccess, onDropError]
  )

  const onDelete = () => {
    setErrors([])
    setFile(undefined)
    onDeleteSuccess()
  }

  React.useEffect(() => {
    if (resetDropzone !== undefined && !!resetDropzone) {
      onDelete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDropzone])

  const { open, isDragActive, isFocused, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
    })

  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="flex w-full flex-col">
        {errors.length === 0 && !file && (
          <div
            {...getRootProps({
              className: cn(
                'flex flex-col w-full h-36 bg-gray-10 items-center justify-center border-2 border-transparent rounded',
                (isFocused || isDragActive) && 'border-aubergine border-dashed',
                file && 'border-green-500 bg-green-200 border-solid'
              ),
            })}
          >
            <input {...getInputProps()} />
            <ArrowUpTrayIcon className="h-10 w-10" />
            <span className="mt-4">
              <Trans>Drag 'n' drop your file</Trans>
            </span>
          </div>
        )}
        {errors.length === 0 && file && (
          <div className="flex h-36 w-full flex-col items-center justify-center rounded border-2 border-green-500 bg-green-200">
            <CheckIcon className="h-10 w-10 text-green-500" />
            <span className="mt-4 text-green-500">
              <Trans>Upload success</Trans>
            </span>
          </div>
        )}
        {errors.length > 0 && <DropZoneParsingErrors parsingErrors={errors} />}
        <div className="flex h-36 flex-col items-center justify-around p-4">
          {!file ? (
            <div className="flex flex-col items-center space-y-2">
              <Button type="button" size="md" variant="gray" onClick={open}>
                <Trans>Select file</Trans>
              </Button>
              <span className="text-xs text-gray-300">
                <Trans>Accepted file format: XLS(X), CSV</Trans>
              </span>
            </div>
          ) : (
            <Button
              onClick={onDelete}
              className="text-md flex items-center space-x-2 break-all rounded p-2"
              variant="none"
            >
              <TrashIcon className="w-6 shrink-0" />
              <span>{file.name}</span>
            </Button>
          )}
        </div>
      </div>
      {!!error && (
        <div className="text-sm font-light text-red-500">{`${error}`}</div>
      )}
    </div>
  )
}

export default DropZone
