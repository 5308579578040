import React from 'react'

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStatus,
  useTransitionStyles,
} from '@floating-ui/react'
import { Trans } from '@lingui/macro'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
  CubeIcon,
  ScaleIcon,
  SwatchIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import useObserver from 'src/hooks/utils/useObserver'

import Currency from 'src/components/Currency'

import { SizeQuantities } from 'src/pages/Carts/CartGrid/components/Item/Quantity'
import StyleSwitcher from 'src/pages/Carts/CartGrid/components/Item/StyleSwitcher'
import { Product } from 'src/pages/Carts/CartGrid/components/Item/types'

import { ReactComponent as MeasureArrowIcon } from 'src/assets/images/measure-arrow.svg'

type DetailsDialogProps = {
  availabilityObserver: any
  cartObserver: any
  closeProductGridViewDetailsDialog: () => void
  currency: string
  product: Product
  pricesObserver: any
  updateCartItems: () => void
  fetchNextPricesPage: any
  fetchNextAvailabilitiesPage: any
  changeDialogProduct: (product: Product) => void
}

const DetailsDialog: React.FC<DetailsDialogProps> = ({
  availabilityObserver,
  cartObserver,
  closeProductGridViewDetailsDialog,
  currency,
  product,
  pricesObserver,
  updateCartItems,
  fetchNextPricesPage,
  fetchNextAvailabilitiesPage,
  changeDialogProduct,
}) => {
  const hasImages = product.images && product.images.length > 0

  const resolveCurrencyIsoCode = (currency: any, product: any) => {
    if (currency !== undefined) {
      return currency
    }
    return product.prices[0]?.currency.code
  }

  const currencyIsoCode = resolveCurrencyIsoCode(currency, product)

  const price = useObserver(
    pricesObserver,
    (data: any) => {
      const price = data?.[product.id]
      if (!price) {
        return false
      }
      return price
    },
    [product.id]
  )

  const onClose = () => {
    closeProductGridViewDetailsDialog()
  }

  const colors = Array.isArray(product.colors)
    ? product.colors
    : product.colors?.split?.(',') ?? []

  const { context, refs } = useFloating({
    onOpenChange: onClose,
  })

  const dismiss = useDismiss(context)

  const { getFloatingProps } = useInteractions([dismiss])

  const { styles } = useTransitionStyles(context, {
    duration: 2000,
    initial: {
      opacity: 1,
      transform: 'transition-duration: 250ms',
    },
  })

  const { status } = useTransitionStatus(context, {
    duration: 2000,
  })

  return (
    <FloatingPortal>
      <FloatingOverlay
        className="fixed inset-0 z-20 flex items-center justify-center overflow-auto bg-gray-900/25"
        lockScroll
      >
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            style={{ ...styles }}
            data-status={status}
            className="fixed right-0 top-14 z-10 flex h-full w-full flex-col overflow-y-auto bg-white pb-12 shadow-2xl md:w-2/3 md:max-w-md lg:w-1/2 xl:top-10 xl:w-3/5 2xl:w-2/5"
            {...getFloatingProps()}
          >
            <button
              className="absolute right-3.5 top-0 z-10 m-2 flex h-10 items-center justify-center md:h-8"
              onClick={onClose}
            >
              <Trans>Close</Trans>
              <XMarkIcon className="h-6 w-6" />
            </button>
            <Swiper
              direction={'vertical'}
              slidesPerView={'auto'}
              className="top-0 h-fit w-full shrink-0"
            >
              <SwiperSlide>
                {hasImages && (
                  <div className="flex h-full w-full bg-gray-10">
                    <Swiper
                      cssMode={true}
                      navigation={true}
                      pagination={true}
                      mousewheel={true}
                      keyboard={true}
                      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                      className="galery swiper-product-image"
                    >
                      {product.images!.map((image, i) => (
                        <SwiperSlide key={i} className="">
                          <img
                            src={image.medium}
                            key={i}
                            alt={product?.style + '-' + i}
                            className="mx-auto my-9 w-96 rounded-lg"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </SwiperSlide>
            </Swiper>
            <div className="p-5">
              <div className="text-sm font-light">{product.brand}</div>
              <div className="mb-2.5 text-xl font-bold">
                <span>
                  {product.model} {` • `} {product.style}{' '}
                  {product.size &&
                    product.groupCount === 1 &&
                    ` • ` + product.size}
                </span>
              </div>
              <div className="flex">
                {product.groupSize === 1 && (
                  <div className="text-base font-extralight">
                    {product.sku}
                    {!product.sku &&
                      product.abstractSku?.replace('Abstract-', '')}
                  </div>
                )}
                <div className="ml-auto flex justify-end text-base font-semibold">
                  <div className="mr-1">
                    <Trans>Price</Trans>:
                  </div>
                  <div>
                    <Currency
                      cents={!!price ? price : 0}
                      currencyIsoCode={
                        !!currencyIsoCode ? currencyIsoCode : 'EUR'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5 px-5">
              <hr />
            </div>
            <div className="px-5 py-4">
              <StyleSwitcher product={product} onChange={changeDialogProduct} />
            </div>
            <div className="mb-5 px-5">
              <hr />
            </div>
            {product.groupCount === 1 &&
            (product.stockStatus === 0 ||
              product.stockStatus === undefined ||
              product.stockStatus === null) ? (
              <div className="px-5">
                <span className="flex h-9 items-center justify-center bg-red-200 py-0.5 text-center font-semibold text-red-600">
                  <Trans>Sold Out</Trans>
                </span>
              </div>
            ) : (
              <div>
                <SizeQuantities
                  product={product}
                  updateCartItems={updateCartItems}
                  cartObserver={cartObserver}
                  priceObserver={pricesObserver}
                  availabilityObserver={availabilityObserver}
                  fetchNextPricesPage={fetchNextPricesPage}
                  fetchNextAvailabilitiesPage={fetchNextAvailabilitiesPage}
                  groupCount={product?.groupCount}
                />
              </div>
            )}
            <div className="p-5">
              <hr />
            </div>
            <div className="px-5">
              <div className="text-1xl font-small float-left font-bold">
                <Trans>Product Details</Trans>
              </div>
            </div>
            <div
              className="mb-12 grid gap-2 px-5 font-light"
              style={{
                gridTemplateColumns: 'minmax(0, auto) minmax(0, auto)',
              }}
            >
              <div className="flex items-center space-x-2">
                <span className="w-4 " />
                <div>
                  <Trans>GTIN</Trans>
                </div>
              </div>

              <div>{product.gtin}</div>

              <div className="flex items-center space-x-2">
                <SwatchIcon className="w-4 text-gray-700" />
                <div>
                  <Trans>Colors</Trans>
                </div>
              </div>

              <div>{colors.join(' • ') || '-'}</div>

              <div className="flex items-center space-x-2">
                <MeasureArrowIcon className="w-4 text-gray-700" />
                <div>
                  <Trans>Dimensions</Trans>
                </div>
              </div>

              <div>
                {product.width && product.height && product.depth ? (
                  <Trans>
                    {product.width} x {product.height} x {product.depth} cm
                  </Trans>
                ) : (
                  '-'
                )}
              </div>

              <div className="flex items-center space-x-2">
                <CubeIcon className="w-4 text-gray-700" />
                <div>
                  <Trans>Volume</Trans>
                </div>
              </div>

              <div>
                {product.volume ? (
                  <Trans>
                    <span>{product.volume} L</span>
                  </Trans>
                ) : (
                  '-'
                )}
              </div>

              <div className="flex items-center space-x-2">
                <ScaleIcon className="w-4 text-gray-700" />
                <div>
                  <Trans>Weight</Trans>
                </div>
              </div>

              <div>
                {product.weight ? <Trans>{product.weight} g</Trans> : '-'}
              </div>
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
}

export default DetailsDialog
