import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import Spinner from 'react-svg-spinner'
import * as Yup from 'yup'

import useClaimCart from 'src/hooks/data/useClaimCart'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

import Button from 'src/components/Button'
import FormErrorMessage from 'src/components/FormErrorMessage'

import { defaultSetValueOptionsForRerendering } from 'src/forms'
import CartSelect from 'src/forms/ClaimCartForm/components/CartSelect'

const schema = Yup.object({
  cartId: Yup.string()
    .required(({ label }) => t`Please select a ${label}`)
    .label(t`cart`)
    .default(''),
}).required()

type ClaimCartFormValues = Yup.InferType<typeof schema>

type ClaimCartFormProps = {
  values?: ClaimCartFormValues
  onSuccess?: (formValues: ClaimCartFormValues, claimedCart: any) => void
  onError?: (error: any) => void
  onCancel?: () => void
}

const ClaimCartForm: React.FC<ClaimCartFormProps> = ({
  values,
  onSuccess,
  onError,
  onCancel,
}) => {
  const { companyId } = useParams()

  const { mutateAsync: claimCart } = useClaimCart()
  const { setValue, handleSubmit, formState, register, watch } =
    useForm<ClaimCartFormValues>({
      mode: 'all',
      resolver: yupResolver(schema),
      values,
    })

  React.useEffect(() => {
    register?.('cartId')
  }, [register])

  const { cartId } = watch()
  const { isValid, isSubmitting } = formState
  const { ability } = useDefaultCompanyUserPermissions()
  const canNotClaimCart = ability.cannot('claim', 'cart')

  const onSubmit = async (formValues: ClaimCartFormValues) => {
    try {
      const claimedCart = await claimCart({
        cartId: formValues.cartId,
      })
      onSuccess?.(formValues, claimedCart)
    } catch (error) {
      onError?.(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      {!!companyId && canNotClaimCart && (
        <FormErrorMessage
          errorMessage={
            <Trans>
              You do not have the permission to claim a cart of this company.
              For further questions, please contact our{' '}
              <Link
                to="/service/contact"
                className="font-medium underline hover:text-gray-600 active:text-gray-600"
              >
                customer service
              </Link>
            </Trans>
          }
        />
      )}

      {companyId && !canNotClaimCart && (
        <CartSelect
          value={cartId}
          companyId={companyId}
          disabled={isSubmitting}
          onChange={(value) => {
            if (value === undefined) {
              setValue('cartId', '', defaultSetValueOptionsForRerendering)
              return
            }

            setValue('cartId', value, defaultSetValueOptionsForRerendering)
          }}
        />
      )}
      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          onClick={onCancel}
          disabled={isSubmitting}
          className="grow"
          variant="none"
          size="xl"
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !isValid}
          className="flex w-full grow items-center justify-center"
          variant="purple"
          size="xl"
        >
          {isSubmitting ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Claim cart</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default ClaimCartForm
