import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import Spinner from 'react-svg-spinner'
import * as Yup from 'yup'

import useReleaseCart from 'src/hooks/data/useReleaseCart'

import Button from 'src/components/Button'

type MinimalCustomer = {
  email: string
}

export type MinimalCart = {
  id: string
  name: string
  originalCustomer: MinimalCustomer
}

const schema = Yup.object({
  cartId: Yup.string()
    .required(({ label }) => t`Please select a ${label}`)
    .label(t`cart`),
}).required()

type ReleaseCartFormValues = Yup.InferType<typeof schema>

type ReleaseCartFormProps = {
  cart: MinimalCart
  onSuccess?: (formValues: ReleaseCartFormValues) => void
  onError?: (error: any) => void
  onCancel?: () => void
}

const ReleaseCartForm: React.FC<ReleaseCartFormProps> = ({
  cart,
  onSuccess,
  onError,
  onCancel,
}) => {
  const { mutateAsync: releaseCart } = useReleaseCart()
  const { register, formState, handleSubmit } = useForm<ReleaseCartFormValues>({
    resolver: yupResolver(schema),
    values: { cartId: cart.id },
  })

  const onSubmit = async (formValues: ReleaseCartFormValues): Promise<void> => {
    try {
      await releaseCart({ cartId: formValues.cartId })
      onSuccess?.(formValues)
      onCancel?.()
    } catch (error) {
      onError?.(error)
    }
  }

  const { isValid, isSubmitting } = formState

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      <input type="hidden" {...register('cartId')} />
      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          onClick={onCancel}
          className="grow"
          size="xl"
          variant="none"
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !isValid}
          className=" flex w-full items-center justify-center"
          variant="purple"
          size="xl"
        >
          {isSubmitting ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Release cart</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default ReleaseCartForm
