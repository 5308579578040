import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Trans } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import Spinner from 'react-svg-spinner'
import * as Yup from 'yup'

import Button from 'src/components/Button'

import exportCartXLS from 'src/lib/exportCartXLS'

const schema = Yup.object({
  cartId: Yup.string().required(),
}).required()

type ExportCartFormValues = Yup.InferType<typeof schema>

type ExportCartFormProps = {
  cartId: string
  onSuccess?: () => void
  onError?: (error: any) => void
  onCancel: () => void
}

const ExportCartForm: React.FC<ExportCartFormProps> = ({
  cartId,
  onSuccess,
  onError,
  onCancel,
}) => {
  const { register, handleSubmit, formState } = useForm<ExportCartFormValues>({
    resolver: yupResolver(schema),
    values: { cartId },
  })

  const onSubmit = async (formValues: ExportCartFormValues) => {
    try {
      await exportCartXLS(formValues.cartId)
      onSuccess?.()
    } catch (error) {
      onError?.(error)
    }
  }

  const { isValid, isSubmitting } = formState

  return (
    <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('cartId')} />

      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          disabled={isSubmitting}
          className="grow"
          variant="none"
          size="xl"
          onClick={onCancel}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !isValid}
          className="flex w-full grow items-center justify-center"
          variant="purple"
          size="xl"
        >
          {isSubmitting ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Export Cart</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default ExportCartForm
