import React from 'react'

import cn from '@meltdownjs/cn'

export enum VARIANTS {
  /* eslint-disable */
  approved = 'state-approved',
  error = 'state-error',
  processed = 'state-processed',
  rejected = 'state-rejected',
  /* eslint-enable */
}

type StateProps = {
  className?: string
  state: string
  variant: string
}

const State: React.FC<StateProps> = ({ className, state, variant }) => {
  const stateClass = VARIANTS[variant] || VARIANTS.ready

  return (
    <div
      className={cn(
        stateClass,
        'flex items-center rounded-3xl border px-4 py-1.5 text-sm',
        className
      )}
    >
      <span>{state}</span>
    </div>
  )
}

export default State
