import React, { Fragment } from 'react'

import { i18n } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'

import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

import { CompanyUser } from 'src/api/company-user-search/types'

import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'
import { makeAvailability } from 'src/hooks/data/usePaginatedAvailabilities'
import usePaginatedProducts from 'src/hooks/data/usePaginatedProducts'

import useObserver from 'src/hooks/utils/useObserver'

import Currency from 'src/components/Currency'

defineMessage({
  id: 'message_max_quantity_number',
  message: 'The product is unfortunately not available in this quantity.',
})

type QuantityInputProps = {
  id: string
  initialQuantity?: number
  inputState?: string
  onChange: ({ id, value }: { id: string; value: number }) => void
  disabled?: boolean
}

export const QuantityInput: React.FC<QuantityInputProps> = ({
  id,
  initialQuantity,
  inputState,
  onChange,
  disabled,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [value, setValue] = React.useState<number>(initialQuantity || 0)

  React.useEffect(() => {
    onChange({ id, value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const increaseValue = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const newValue = value + 1

    setValue(newValue)
  }

  const decreaseValue = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const newValue = value > 1 ? value - 1 : 0

    if (!Number.isInteger(newValue)) {
      setTimeout(() => {
        inputRef?.current?.focus?.()
      })
    }

    setValue(newValue)
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10)
    if (newValue === value) {
      return
    }

    if (!Number.isNaN(newValue)) {
      setValue(newValue)
      return
    }

    setValue(0)
  }

  return (
    <div className="flex rounded">
      <button
        className="button-color-gray flex items-center rounded-l-md border p-2.5"
        onClick={decreaseValue}
        disabled={disabled}
      >
        <MinusIcon className="h-6 w-6" />
      </button>
      <div>
        <input
          ref={inputRef}
          data-ignore-focus-style
          onChange={onInputChange}
          disabled={disabled}
          className={cn(
            'h-12 w-14 appearance-none border p-2.5 text-center placeholder-gray-200',
            {
              'bg-gray-100 text-gray-800': inputState === 'pending',
              'bg-red-50 text-red-800': inputState === 'invalid',
              'bg-green-50 text-green-800': inputState === 'valid',
            }
          )}
          placeholder="0"
          value={value}
        />
      </div>
      <button
        className="button-color-gray flex items-center rounded-r-md border p-2.5"
        disabled={disabled}
        onClick={increaseValue}
      >
        <PlusIcon className="h-6 w-6" />
      </button>
    </div>
  )
}

export const SizeQuantities = React.memo(
  ({
    product,
    cartObserver,
    priceObserver,
    availabilityObserver,
    updateCartItems,
    fetchNextPricesPage,
    fetchNextAvailabilitiesPage,
    groupCount,
  }: {
    product: any
    cartObserver: any
    priceObserver: any
    availabilityObserver: any
    updateCartItems: any
    fetchNextPricesPage: any
    fetchNextAvailabilitiesPage: any
    groupCount: number
  }) => {
    const defaultCompanyUserQuery = useDefaultCompanyUser<CompanyUser>()
    const companyUser = defaultCompanyUserQuery.data

    const productsQuery = usePaginatedProducts(
      {
        companyUserReference: companyUser?.companyUserReference,
        groupHash: [product.groupHash],
      },
      {
        onSuccess: ({ pages }: { pages: any[] }) => {
          const products = pages?.[pages.length - 1]?.abstractProducts as any[]
          fetchNextPricesPage({
            pageParam: products.map(({ abstractSku }) => abstractSku),
          })
          fetchNextAvailabilitiesPage({
            pageParam: products.map(({ abstractSku }) => abstractSku),
          })
        },
        enabled: !!product.groupHash,
      }
    )

    //`${product.id}.earliest-date`
    const products = !!productsQuery?.data?.items?.length
      ? productsQuery?.data?.items
      : [product]

    if (products.length === 0) {
      return null
    }

    const onChange = ({ id, value }: { id: string; value: number }) => {
      updateCartItems({
        [id]: value,
      })
    }

    return products.map((product) => (
      <QuantityRow
        key={product.id}
        product={product}
        cartObserver={cartObserver}
        pricesObserver={priceObserver}
        availabilityObserver={availabilityObserver}
        onChange={onChange}
        groupCount={groupCount}
      />
    ))
  }
)

type QuantityRowProps = {
  product: any
  cartObserver: any
  pricesObserver: any
  availabilityObserver: any
  onChange: ({ id, value }: { id: string; value: number }) => void
  groupCount: number
}

const QuantityRow = React.memo(
  ({
    product,
    cartObserver,
    pricesObserver,
    availabilityObserver,
    onChange,
    groupCount,
  }: QuantityRowProps) => {
    const id = `${product.id}.earliest-date`
    const { quantity, messages } = useObserver(
      cartObserver,
      (data: any) => ({
        quantity: data.items[id]?.quantity || 0,
        messages: data.items[id]?.messages,
        companyUserReference: data.companyUserReference,
      }),
      [id]
    )

    let inputState = ''
    if (quantity > 0) {
      inputState =
        messages === null
          ? 'pending'
          : messages && messages.length === 0
          ? 'valid'
          : messages && messages.length > 0 && 'invalid'
    }

    const price = useObserver(
      pricesObserver,
      (data: any) => {
        const price = data?.[product.id]
        if (!price) {
          return 0
        }
        return price
      },
      [product.id]
    )

    const availability = useObserver(
      availabilityObserver,
      (data: any) => {
        if (data === undefined) {
          return false
        }
        const periods = data[product.id] || []
        const availability = makeAvailability(periods)
        return availability
      },
      [product.id]
    )

    const currencyIsoCode = product.prices[0]?.currency.code

    return (
      <div className="flex flex-row px-5 py-4" key={id}>
        <ProductInfo
          size={product.size}
          productId={product.id}
          earliestDeliveryDate={availability.earliestDeliveryDate}
          stockStatus={product.stockStatus}
          groupCount={groupCount}
        />
        <div className="flex flex-2 justify-end space-x-4">
          <div className="flex items-center">
            <QuantityInput
              id={id}
              disabled={
                product.stockStatus === 0 ||
                product.stockStatus === undefined ||
                product.stockStatus === null
              }
              inputState={inputState}
              initialQuantity={quantity}
              onChange={onChange}
            />
          </div>
          <div className="flex items-center">
            <Currency
              className="min-w-20 text-base font-bold text-gray-900"
              cents={price * quantity}
              currencyIsoCode={!!currencyIsoCode ? currencyIsoCode : 'EUR'}
            />
          </div>
        </div>
      </div>
    )
  }
)

type ProductInfoProps = {
  size: string
  productId: string
  stockStatus: number
  earliestDeliveryDate: string
  groupCount: number
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  size,
  productId,
  stockStatus,
  earliestDeliveryDate,
  groupCount,
}) => (
  <div
    className={cn('flex flex-1 flex-col justify-center rounded-md px-4 py-1 ', {
      'bg-yellow-100 text-yellow-700': stockStatus === 1,
      'bg-red-100 text-red-600':
        stockStatus === 0 || stockStatus === undefined || stockStatus === null,
    })}
  >
    {groupCount > 1 && (
      <Fragment>
        <div className="text-sm">{t`Size ${size}`}</div>
        <div className="text-xs">{productId}</div>
      </Fragment>
    )}
    <div
      className={cn('mt-1 text-xs', {
        'text-sm': groupCount === 1 || groupCount === null,
      })}
    >
      {(stockStatus === 1 || stockStatus === 2) && (
        <Trans>
          Available at{' '}
          {i18n.date(earliestDeliveryDate, {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })}
        </Trans>
      )}
      {(stockStatus === 0 ||
        stockStatus === undefined ||
        stockStatus === null) && <Trans>Not available</Trans>}
    </div>
  </div>
)
