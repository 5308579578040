import React from 'react'
import { Link } from 'react-router-dom'

import { Trans, t } from '@lingui/macro'

import Button from 'src/components/Button'
import {
  Dialog,
  DialogContainer,
  DialogContent,
  DialogContextType,
  DialogHeading,
  DialogTrigger,
  DialogXButton,
} from 'src/components/Dialog'

import ClaimCartForm from 'src/forms/ClaimCartForm'
import { openFailureToast, openSuccessToast } from 'src/lib/toast'

const ClaimCartDialog = () => {
  const [claimedCart, setClaimedCart] = React.useState<any>(null)
  const resetClaimedCart = () => {
    setClaimedCart(null)
  }
  return (
    <Dialog onClose={resetClaimedCart}>
      <DialogTrigger asChild>
        <Button className="text-sm" size="md" variant="gray">
          <Trans>Claim cart</Trans>
        </Button>
      </DialogTrigger>
      <DialogContainer>
        <DialogXButton />
        <DialogHeading>
          <Trans>Claim cart</Trans>
        </DialogHeading>
        <DialogContent>
          {(context: DialogContextType) => {
            const dismiss = () => context.setOpen(false)

            return claimedCart ? (
              <Link
                className="flex h-10 grow items-center justify-center space-x-4"
                to={`${claimedCart?.cartId}`}
              >
                <Trans>Navigate to claimed Cart</Trans>
              </Link>
            ) : (
              <ClaimCartForm
                onCancel={dismiss}
                onSuccess={(_formValues, cart) => {
                  setClaimedCart(cart)
                  openSuccessToast(t`Cart claimed successfully`)
                }}
                onError={() => {
                  openFailureToast(t`Cart could not be claimed`)
                  dismiss()
                }}
              />
            )
          }}
        </DialogContent>
      </DialogContainer>
    </Dialog>
  )
}

export default ClaimCartDialog
