import React from 'react'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import Spinner from 'react-svg-spinner'
import * as Yup from 'yup'

import { CompanyUser } from 'src/api/company-user-search/types'

import useCreateCartWithItems from 'src/hooks/data/useCreateCartWithItems'
import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

import Button from 'src/components/Button'
import FormErrorMessage from 'src/components/FormErrorMessage'

import { defaultSetValueOptionsForRerendering } from 'src/forms'
import Dropzone, {
  MinimalCart,
} from 'src/forms/UploadExcelForm/components/Dropzone'

const schema = Yup.object({
  companyUserId: Yup.string().required(),
  name: Yup.string().required(),
  lineItems: Yup.object()
    .required(t`Please upload a excel file`)
    .nullable()
    .test({
      name: 'minItems',
      message: t`Something went wrong. Please check your excel file for errors`,
      test: (value: any) => value && Object.keys(value).length > 0,
    })
    .test({
      name: 'validQuantity',
      message: (params) => {
        const combinations: string[] = []

        for (const key of Object.keys(params?.value || {})) {
          if (params.value[key].quantity >= 0) {
            continue
          }

          combinations.push(
            key.replace(/^(.+)\.(\d{4,4}-\d{2,2}-\d{2,2})$/, '$1 / $2')
          )
        }

        return t`The quantity for the following combinations are not valid: ${combinations.join(
          ', '
        )}`
      },
      test: (value: any) =>
        value &&
        Object.values(value).findIndex(
          (lineItem: any) => lineItem.quantity < 0
        ) === -1,
    })
    .default({}),
}).required()

type UploadExcelFormValues = Yup.InferType<typeof schema>

type UploadExcelFormProps = {
  onSuccess?: (cart: MinimalCart) => void
  onError?: (error: unknown) => void
  onCancel?: () => void
}

const UploadExcelForm: React.FC<UploadExcelFormProps> = ({
  onSuccess,
  onError,
  onCancel,
}) => {
  const { data: defaultCompanyUser } = useDefaultCompanyUser<CompanyUser>()
  const { ability } = useDefaultCompanyUserPermissions()

  const { register, setValue, handleSubmit, formState } =
    useForm<UploadExcelFormValues>({
      resolver: yupResolver(schema),
    })

  const {
    mutateAsync: createCart,
  }: { mutateAsync: (_variables: any) => Promise<any> } =
    useCreateCartWithItems()

  React.useEffect(() => {
    register?.('companyUserId')
    register?.('name')
    register?.('lineItems')
  }, [register])

  const canUploadCart = ability.can('upload', 'cart')

  React.useEffect(() => {
    setValue(
      'companyUserId',
      defaultCompanyUser?.companyUserReference || '',
      defaultSetValueOptionsForRerendering
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCompanyUser?.companyUserReference])

  const onSubmit = async (formValues: UploadExcelFormValues): Promise<void> => {
    try {
      const cart: MinimalCart = await createCart({
        name: formValues.name,
        companyUserId: formValues.companyUserId,
        lineItems: formValues.lineItems,
      })

      onSuccess?.(cart)
    } catch (error) {
      onError?.(error)
    }
  }

  const { isValid, isSubmitting, errors } = formState

  const onDropAccepted = (minimalCart: MinimalCart): void => {
    setValue('name', minimalCart.name, defaultSetValueOptionsForRerendering)
    setValue(
      'lineItems',
      minimalCart.lineItems,
      defaultSetValueOptionsForRerendering
    )
  }

  const onDeleteFile = (_path: string): void => {
    setValue('name', '', defaultSetValueOptionsForRerendering)
    setValue('lineItems', null, defaultSetValueOptionsForRerendering)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      <input type="hidden" {...register('companyUserId')} />

      {!canUploadCart && (
        <FormErrorMessage
          errorMessage={
            <Trans>
              You do not have the permission to upload a cart for this company.
              For further questions, please contact our{' '}
              <Link
                to="/service/contact"
                className="font-medium underline hover:text-gray-600 active:text-gray-600"
              >
                customer service
              </Link>
            </Trans>
          }
        />
      )}

      {canUploadCart && (
        <Dropzone
          error={errors.lineItems?.message}
          onDropAccepted={onDropAccepted}
          onDeleteFile={onDeleteFile}
        />
      )}
      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          onClick={onCancel}
          className="grow"
          size="xl"
          variant="none"
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !isValid}
          className="flex w-full grow items-center justify-center"
          size="xl"
          variant="purple"
        >
          {isSubmitting ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Create cart</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default UploadExcelForm
