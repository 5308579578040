import React, { Fragment, useState } from 'react'
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import {
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react'
import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import {
  EyeIcon,
  Squares2X2Icon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'

const CartViewSwitcher = () => {
  const { cartId, companyId } = useParams()
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const navigate = useNavigate()
  const navigateToList = () => navigate(`/${companyId}/carts/${cartId}`)
  const navigateToGrid = () => navigate(`/${companyId}/carts/${cartId}/grid`)

  const isGrid = !!matchPath('/:companyId/carts/:cartId/grid', pathname)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [offset(5)],
  })

  const click = useClick(context)
  const dismiss = useDismiss(context, { ancestorScroll: true })

  const listRef = React.useRef<(HTMLElement | null)[]>([])

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    focusItemOnHover: true,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    listNavigation,
    click,
    dismiss,
  ])

  return (
    <Fragment>
      <button
        ref={refs.setReference}
        className="button-color-gray button-size-md ml-auto flex h-10 shrink-0 items-center space-x-2 px-4 lg:h-8"
        {...getReferenceProps({
          onClick: (event) => {
            event.stopPropagation()
          },
        })}
      >
        <span>
          <EyeIcon className="w-4" />
        </span>
        <span className="hidden md:block">
          <Trans>View</Trans>
        </span>
        <span className="ml-auto">
          {isOpen ? (
            <ChevronUpIcon className="w-5" />
          ) : (
            <ChevronDownIcon className="w-5" />
          )}
        </span>
      </button>

      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="z-10 w-2/5 rounded-lg bg-white text-sm shadow-xl lg:w-auto"
            {...getFloatingProps()}
          >
            <div
              className={cn(
                'flex h-8 cursor-pointer items-center whitespace-nowrap px-4 py-6 hover:bg-gray-300 focus:ring-0',
                { 'bg-gray-100 text-purple': !isGrid }
              )}
              onClick={navigateToList}
            >
              <span className="text-current">
                <TableCellsIcon className="w-6" />
              </span>
              <span className="flex h-8 cursor-pointer items-center space-x-2 px-4 text-current">
                <Trans>Detailed</Trans>
              </span>
            </div>
            <hr></hr>
            <div
              className={cn(
                'flex h-8 cursor-pointer items-center whitespace-nowrap px-4 py-6 hover:bg-gray-300 focus:ring-0',
                { 'bg-gray-100 text-purple': isGrid }
              )}
              onClick={navigateToGrid}
            >
              <span className="text-current">
                <Squares2X2Icon className="w-6" />
              </span>
              <span className="flex h-8 cursor-pointer items-center space-x-2 px-4 text-current">
                <Trans>Simplified</Trans>
              </span>
            </div>
          </div>
        </FloatingPortal>
      )}
    </Fragment>
  )
}

export default CartViewSwitcher
