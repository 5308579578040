import React from 'react'

import { Trans, defineMessage } from '@lingui/macro'
import Spinner from 'react-svg-spinner'

import { ErpOrderCancellation } from 'src/api/erp-order-cancellation/types'

import Button from 'src/components/Button'
import Currency from 'src/components/Currency'

defineMessage({ id: 'Submit changes', message: 'Submit changes' })
defineMessage({
  id: 'Submit and approve changes',
  message: 'Submit and approve changes',
})

type FooterProps = {
  formState?: any
  cancellationRequest: ErpOrderCancellation
  total: number
  quantity: number
  setSubmitStatus: (status: string) => void
  submitStatus: string
}

const Footer: React.FC<FooterProps> = ({
  formState,
  cancellationRequest,
  total,
  quantity,
  setSubmitStatus,
  submitStatus,
}) => {
  return (
    <div className="mb-2 flex shrink-0 flex-col-reverse items-end space-y-2 space-y-reverse border-t border-gray-300 bg-white p-4 md:mb-0 md:space-x-4 md:space-y-0 md:space-y-0">
      <div className="group relative flex h-full items-center space-x-4">
        <div className="leading-snug">
          <div className="font-bold">
            {
              <Currency
                cents={total}
                currencyIsoCode={cancellationRequest.currencyIsoCode}
              />
            }
          </div>
          <div className="text-sm text-gray-500">
            {quantity}{' '}
            {quantity > 1 ? (
              <Trans>Canceled items</Trans>
            ) : (
              <Trans>Canceled item</Trans>
            )}
          </div>
        </div>
        <Button type="reset" variant="gray" size="xl">
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          variant="purple"
          size="xl"
          onClick={() => setSubmitStatus('submit')}
        >
          {formState?.isSubmitting && submitStatus === 'submit' ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Submit changes</Trans>
          )}
        </Button>
        <Button
          type="submit"
          variant="purple"
          size="xl"
          onClick={() => setSubmitStatus('submitAndSave')}
        >
          {formState?.isSubmitting && submitStatus === 'submitAndSave' ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Submit and approve changes</Trans>
          )}
        </Button>
      </div>
    </div>
  )
}

export default Footer
