import { toast } from 'react-toastify'

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'

const ToastCloseButton = () => <XMarkIcon className="h-4 w-4" />

export const openSuccessToast = (message) =>
  toast.success(message, {
    icon: CheckCircleIcon,
    closeButton: ToastCloseButton,
    className: 'text-sm !bg-green-500',
    autoClose: 2000,
  })

export const openFailureToast = (message) =>
  toast.warning(message, {
    icon: ExclamationCircleIcon,
    closeButton: ToastCloseButton,
    className: 'text-sm !bg-yellow-500',
    autoClose: 3000,
  })

export const openInfoToast = (message) =>
  toast.info(message, {
    icon: ExclamationCircleIcon,
    closeButton: ToastCloseButton,
    className: 'text-sm',
    autoClose: 3000,
  })
