import React from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import Budget from 'src/pages/Tools/Budget'
import CancellationRequest from 'src/pages/Tools/CancellationRequest'

import lazyRetry from 'src/lib/lazyRetry'

const ProductLists = React.lazy(() => import('src/pages/Tools/ProductLists'))
const Customer = React.lazy(() =>
  lazyRetry(() => import('src/pages/Tools/ProductLists/Customer'))
)
const CustomerUpload = React.lazy(() =>
  lazyRetry(() => import('src/pages/Tools/ProductLists/CustomerUpload'))
)
const Distribution = React.lazy(() =>
  lazyRetry(() => import('src/pages/Tools/ProductLists/Distribution'))
)
const CancellationRequests = React.lazy(() =>
  lazyRetry(() => import('src/pages/Tools/CancellationRequests'))
)
const AssignmentWizard = React.lazy(() =>
  lazyRetry(() => import('src/pages/Tools/CompanyAssignment/AssignmentWizard'))
)

const CompanyAssignment = React.lazy(() =>
  lazyRetry(() => import('src/pages/Tools/CompanyAssignment'))
)

const Representations = React.lazy(() =>
  lazyRetry(() => import('src/pages/Tools/Representations'))
)

const toolsRoute: RouteObject = {
  path: 'tools',
  element: (
    <div className="container mx-auto flex grow flex-col px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
      <Outlet />
    </div>
  ),
  children: [
    {
      path: 'product-lists',
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'product-lists',
              }}
            >
              <ProductLists />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: 'customer',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'product-lists_customer',
              }}
            >
              <Customer />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: 'customer-upload',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'product-lists_customer-upload',
              }}
            >
              <CustomerUpload />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: 'distribution',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'product-lists_distribution',
              }}
            >
              <Distribution />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: 'company-assignment',
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'company-assignment',
              }}
            >
              <CompanyAssignment />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: 'wizard',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'company-assignment_wizard',
              }}
            >
              <AssignmentWizard />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: 'representations',
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'representations',
              }}
            >
              <Representations />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: 'budget',
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'budget',
              }}
            >
              <Budget />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: 'cancellation-requests',
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'cancellation-requests',
              }}
            >
              <CancellationRequests />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: ':cancellationRequestId',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'cancellation_request_detail',
              }}
            >
              <CancellationRequest />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="product-lists" />,
    },
  ],
}

export default toolsRoute
