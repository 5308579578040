import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import Spinner from 'react-svg-spinner'
import * as Yup from 'yup'

import useOrderBudgetsBulk from 'src/hooks/data/useOrderBudgetsBulk'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

import Button from 'src/components/Button'
import FormErrorMessage from 'src/components/FormErrorMessage'

import csvExampleFile from 'src/assets/examples/budgets-update-example.csv'
import xlsxExampleFile from 'src/assets/examples/budgets-update-example.xlsx'
import { defaultSetValueOptionsForRerendering } from 'src/forms'
import ExplanationBlock from 'src/forms/UploadWeeklyBudgetsForm/components/ExplanationBlock'
import DropZone from 'src/forms/components/DropZone'
import { ParsingError } from 'src/forms/components/DropZone/error'

type Row = {
  debtorNumber: string
  nextInitialBudget: number
}

type Company = {
  debtorNumber: string
}

type OrderBudget = {
  company: Company
  nextInitialBudget: number
}

type OrderBudgets = OrderBudget[]

type OrderBudgetsResponse = {
  data: {
    attributes: {
      invalidIndexes: number[]
    }
  }
}

const mapRowsToOrderBudgetItems = (rows: Row[]): OrderBudgets => {
  const orderBudgets: OrderBudgets = []

  for (const row of rows) {
    orderBudgets.push({
      company: {
        debtorNumber: row.debtorNumber,
      },
      nextInitialBudget: row.nextInitialBudget,
    })
  }

  return orderBudgets
}

const schema = Yup.object({
  orderBudgets: Yup.array()
    .required(t`Please upload a excel file`)
    .nullable()
    .test({
      name: 'minItems',
      message: t`Something went wrong. Please check your excel file for errors`,
      test: (value: any) => value && Object.keys(value).length > 0,
    })
    .test({
      name: 'validBudgets',
      message: (params) => {
        const invalidRows: number[] = []
        for (let i = 0; i < params.value.length; i++) {
          if (params.value[i].nextInitialBudget >= 0) {
            continue
          }

          invalidRows.push(i + 1)
        }

        return t`The budget of the following lines is not valid: ${invalidRows.join(
          ', '
        )}`
      },
      test: (value: any) =>
        value &&
        Object.values(value).findIndex(
          (lineItem: any) => lineItem.quantity < 0
        ) === -1,
    })
    .default([]),
}).required()

type UploadOrderBudgetsFormValues = Yup.InferType<typeof schema>

type UploadOrderBudgetsFormProps = {
  onSuccess?: ({ invalidLines }: { invalidLines?: any[] }) => void
  onError?: (error: any) => void
  onCancel?: () => void
}

const UploadWeeklyBudgetsForm: FC<UploadOrderBudgetsFormProps> = ({
  onSuccess,
  onError,
  onCancel,
}) => {
  const { ability } = useDefaultCompanyUserPermissions()

  const { register, setValue, handleSubmit, formState, watch } =
    useForm<UploadOrderBudgetsFormValues>({
      resolver: yupResolver(schema),
    })

  const {
    mutateAsync: orderBudgetBulk,
  }: { mutateAsync: (_variables: any) => Promise<any> } = useOrderBudgetsBulk()

  useEffect(() => {
    register?.('orderBudgets')
  }, [register])

  const canUpdateOrderBudgets = ability.can('update', 'orderBudget')

  const onSubmit = async (
    formValues: UploadOrderBudgetsFormValues
  ): Promise<void> => {
    try {
      const orderBudgetBulkResponse: OrderBudgetsResponse =
        await orderBudgetBulk({
          orderBudgets: formValues.orderBudgets,
        })
      const invalidIndexes =
        orderBudgetBulkResponse?.data?.attributes?.invalidIndexes || []

      const invalidLines = formValues?.orderBudgets?.reduce(
        (collection, item, index) => {
          if (invalidIndexes.includes(index)) {
            collection[index + 1] = item
          }
          return collection
        },
        {}
      )
      onSuccess?.({
        invalidLines,
      })
    } catch (error) {
      onError?.(error)
    } finally {
      setValue('orderBudgets', [], defaultSetValueOptionsForRerendering)
    }
  }

  const { isValid, isSubmitting } = formState

  const onDropSuccess = (rows: Row[]): void => {
    const orderBudgetItems = mapRowsToOrderBudgetItems(rows)
    setValue(
      'orderBudgets',
      orderBudgetItems,
      defaultSetValueOptionsForRerendering
    )
  }

  const onDropError = (_parsingErrors: ParsingError[]): void => {
    setValue('orderBudgets', [], defaultSetValueOptionsForRerendering)
  }

  const onDeleteSuccess = (): void => {
    setValue('orderBudgets', [], defaultSetValueOptionsForRerendering)
  }

  const rowSchema = Yup.tuple([
    Yup.string()
      .label(t`Debtor number`)
      .required(),
    Yup.number()
      .label(t`Next initial budget`)
      .positive()
      .integer()
      .required(),
  ]).required()

  const parsedRowMapper = (row: any[]): Row => {
    const [debtorNumber, nextInitialBudget] = rowSchema.validateSync(row)

    return { debtorNumber, nextInitialBudget }
  }

  const onReset = (): void => {
    setValue('orderBudgets', [], defaultSetValueOptionsForRerendering)
    onCancel?.()
  }

  const { orderBudgets } = watch()

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      <div className="space-y-2">
        <p>
          <Trans>
            Here you can upload an Excel- / CSV-File to quickly update the
            weekly budgets. Please follow our example file when creating your
            file.
          </Trans>
        </p>
        <ExplanationBlock />
        <p className="flex space-x-2 text-sm text-gray-700">
          <span>
            <a
              className="underline"
              href={xlsxExampleFile}
              target="_blank"
              rel="noopener noreferrer"
              download="budgets-update-example.xlsx"
            >
              <Trans>XLSX-Example</Trans>
            </a>
          </span>
          <span>|</span>
          <span>
            <a
              className="underline"
              href={csvExampleFile}
              target="_blank"
              rel="noopener noreferrer"
              download="budgets-update-example.csv"
            >
              <Trans>CSV-Example</Trans>
            </a>
          </span>
        </p>
      </div>

      {!canUpdateOrderBudgets && (
        <FormErrorMessage
          errorMessage={
            <Trans>
              You do not have the permission to update weekly budgets. For
              further questions, please contact our{' '}
              <Link
                to="/service/contact"
                className="font-medium underline hover:text-gray-600 active:text-gray-600"
              >
                customer service
              </Link>
            </Trans>
          }
        />
      )}

      {canUpdateOrderBudgets && (
        <DropZone
          resetDropzone={orderBudgets?.length === 0}
          onDeleteSuccess={onDeleteSuccess}
          onDropSuccess={onDropSuccess}
          onDropError={onDropError}
          rowMapper={parsedRowMapper}
        />
      )}
      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          onClick={onReset}
          className="grow"
          size="xl"
          variant="none"
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !isValid}
          className="flex w-full grow items-center justify-center"
          size="xl"
          variant="purple"
        >
          {isSubmitting ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Update weekly budgets</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default UploadWeeklyBudgetsForm
