import React from 'react'

import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'

import Button from 'src/components/Button'

import Input from 'src/forms/components/Input'

type QuantityInputProps = {
  currentValue: number
  maxValue: number
  setValue: (value: number) => void
  onBlur: () => void
  hasError?: boolean
  hasWarning?: boolean
  placeholder?: string
}

const QuantityInput = React.forwardRef<HTMLInputElement, QuantityInputProps>(
  ({ setValue, currentValue = 0, maxValue, ...props }, ref) => {
    const increaseValue = () => {
      if (currentValue === maxValue) {
        return
      }
      setValue(currentValue + 1)
    }

    const decreaseValue = () => {
      if (currentValue === 0) {
        return
      }

      setValue(currentValue - 1)
    }

    return (
      <div className="flex">
        <Button
          type="button"
          size="md"
          color="gray"
          className="flex h-8 w-8 items-center justify-center rounded-r-none border p-0"
          onClick={decreaseValue}
        >
          <MinusIcon className="h-4 w-4" />
        </Button>
        <div>
          <Input
            ref={ref}
            data-ignore-focus-style
            className="h-8 w-12 appearance-none border p-1 text-center placeholder-gray-200"
            placeholder="0"
            {...props}
          />
        </div>
        <Button
          type="button"
          size="md"
          color="gray"
          className="flex h-8 w-8 items-center justify-center rounded-l-none border p-0"
          onClick={increaseValue}
        >
          <PlusIcon className="h-4 w-4" />
        </Button>
      </div>
    )
  }
)

export default QuantityInput
