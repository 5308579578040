import React from 'react'
import { Navigate, RouteObject, redirectDocument } from 'react-router-dom'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import lazyRetry from 'src/lib/lazyRetry'
import cartsRoute from 'src/routes/carts'
import companyRoute from 'src/routes/company'
import documentsRoute from 'src/routes/documents'
import downloadsRoute from 'src/routes/downloads'
import {
  canVisitUrl,
  getDefaultPathByCompanyUser,
} from 'src/routes/permissions'
import serviceRoute from 'src/routes/service'
import toolsRoute from 'src/routes/tools'
import {
  getCompanyUserByCompanyId,
  getDefaultCompanyUser,
  getIsInitialLogin,
  getIsLoggedIn,
  setDefaultCompanyUser,
} from 'src/routes/utils'

const InitialLogin = React.lazy(() =>
  lazyRetry(() => import('src/pages/InitialLogin'))
)

const ReleaseNotes = React.lazy(() =>
  lazyRetry(() => import('src/pages/ReleaseNotes'))
)

const Dashboard = React.lazy(() =>
  lazyRetry(() => import('src/pages/Dashboard'))
)

const Sandbox = React.lazy(() => lazyRetry(() => import('src/pages/Sandbox')))

const loader = async ({ params, request }: any) => {
  const isLoggedIn = await getIsLoggedIn()

  if (!isLoggedIn) {
    return redirectDocument('/login')
  }

  const isInitialLogin = await getIsInitialLogin()

  if (isInitialLogin) {
    return redirectDocument('/initial-login')
  }

  const defaultCompanyUser = await getDefaultCompanyUser()
  const companyUser = await getCompanyUserByCompanyId(params.companyId)
  let currentCompanyUser = defaultCompanyUser

  if (!companyUser) {
    return redirectDocument('/')
  }

  if (
    !defaultCompanyUser ||
    defaultCompanyUser?.companyUserReference !==
      companyUser?.companyUserReference
  ) {
    await setDefaultCompanyUser(companyUser)
    currentCompanyUser = companyUser
  }

  if (!canVisitUrl(request.url, currentCompanyUser!)) {
    return redirectDocument(getDefaultPathByCompanyUser(currentCompanyUser!))
  }

  return currentCompanyUser
}

const initialLoginLoader = async () => {
  const isInitialLogin = await getIsInitialLogin()

  if (!isInitialLogin) {
    return redirectDocument('/')
  }

  return null
}

const releaseNotesLoader = async () => {
  const isLoggedIn = await getIsLoggedIn()

  if (!isLoggedIn) {
    return redirectDocument('/login')
  }

  return null
}

const privateRoutes: RouteObject[] = [
  {
    path: ':companyId',
    loader,
    children: [
      {
        index: true,
        element: (
          <DataLayerPushWrapper
            layer={{
              event: 'vpv_general',
              page_type: 'dashboard',
            }}
          >
            <Dashboard />
          </DataLayerPushWrapper>
        ),
      },
      cartsRoute,
      companyRoute,
      documentsRoute,
      downloadsRoute,
      serviceRoute,
      toolsRoute,
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    path: 'sandbox',
    element: (
      <div className="container mx-auto flex grow flex-col px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
        <Sandbox />
      </div>
    ),
  },
  {
    path: 'initial-login',
    loader: initialLoginLoader,
    element: (
      <DataLayerPushWrapper
        layer={{
          event: 'vpv_general',
          page_type: 'initial-login',
        }}
      >
        <div className="flex h-full w-full flex-col">
          <InitialLogin />
        </div>
      </DataLayerPushWrapper>
    ),
  },
  {
    path: 'releases',
    loader: releaseNotesLoader,
    element: (
      <DataLayerPushWrapper
        layer={{
          event: 'vpv_general',
          page_type: 'release_notes',
        }}
      >
        <ReleaseNotes />
      </DataLayerPushWrapper>
    ),
  },
]

export default privateRoutes
