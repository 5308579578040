import { QueryClientProvider } from 'react-query'
import queryClient from 'src/react-query-client'

import 'intersection-observer'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { ReactQueryDevtools } from 'react-query/devtools'

import Root from 'src/Root'
import setupYupLocales from 'src/setupYupLocales'
import 'src/styles/index.css'

import * as serviceWorker from './serviceWorker'

setupYupLocales()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <QueryClientProvider client={queryClient}>
    <Root />

    {ReactDOM.createPortal(
      <ReactQueryDevtools initialIsOpen={false} />,
      document.body
    )}
  </QueryClientProvider>
)

serviceWorker.unregister()
